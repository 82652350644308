import { routerReducer } from 'react-router-redux';
import { combineReducers } from "redux";
import EnplugStore from "./EnplugStore";
import ProjectStore from "./ProjectStore";
import CategoryStore from "./CategoryStore";
import BusinessStore from "./BusinessStore";
import AdsStore from "./AdsStore";

const reducers = combineReducers({
  routing: routerReducer,
  EnplugStore: EnplugStore,
  ProjectStore: ProjectStore,
  CategoryStore: CategoryStore,
  BusinessStore: BusinessStore,
  AdsStore: AdsStore
})

export default reducers;