import constants from "../constants/index";
const initState = {
  categories: null,
  category: null
}

const CategoryStore = (state = initState, action) => {
  switch (action.type) {
    case constants.setCategories:
      return {
        ...state,
        categories: action.payload.categories    
      };
    case constants.setCategory:
      return {
        ...state,
        category: action.payload.category    
      };
    case constants.addCategory:
      return {
        ...state,
        category: action.payload.category  
      };
    case constants.editCategory:
      return {
        ...state,
        category: action.payload.category  
      };
    case constants.deleteCategory:
      return {
        ...state,
        category: null  
      };
    default:
      return state;
  }
 }

export default CategoryStore;