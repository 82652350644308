export default {
  // Enplug
  getUserId: "GET_USER_ID",
  getAssets: "GET_ASSETS",
  deleteAllAssets: "DELETE_ALL_ASSETS",
  public: "PUBLIC",
  // Projects
  setProjects: "SET_PROJECTS",
  setProject: "SET_PROJECT",
  setProjectById: "SET_PROJECT_BY_ID",
  resetProject: "RESET_PROJECT",
  addProject: "ADD_PROJECT",
  editProject: "EDIT_PROJECT",
  deleteProject: "DELETE_PROJECT",
  // Categories
  setCategories: "SET_CATEGORIES",
  setCategory: "SET_CATEGORY",
  setCategoryById: "SET_CATEGORY_BY_ID",
  resetCategory: "RESET_CATEGORY",
  addCategory: "ADD_CATEGORY",
  editCategory: "EDIT_CATEGORY",
  deleteCategory: "DELETE_CATEGORY",
  // Buisnesses
  setBusinesses: "SET_BUSINESSES",
  setBusiness: "SET_BUSINESS",
  setBusinessById: "SET_BUSINESS_BY_ID",
  resetBusiness: "RESET_BUSINESS",
  addBusiness: "ADD_BUSINESS",
  editBusiness: "EDIT_BUSINESS",
  deleteBusiness: "DELETE_BUSINESS",
  // Ads
  setAds: "SET_ADS",
  setAd: "SET_AD",
  setAdById: "SET_AD_BY_ID",
  resetAd: "RESET_AD",
  addAd: "ADD_AD",
  editAd: "EDIT_AD",
  deleteAd: "DELETE_AD",
}