import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/images/app-logo.jpg";

const Header = props => {
  const history = useHistory();
  const ProjectStore = useSelector(state => state.ProjectStore);
  const CategoryStore = useSelector(state => state.CategoryStore);
  const [ path, setPath ] = useState("/");
  const isProject = path.indexOf("projects/") != -1 ? true : false;
  const isCategory = path.indexOf("categories/") != -1 ? true : false;
  const isBusiness = path.indexOf("business/") != -1 ? true : false;

  useEffect(() => { 
    return history.listen((location) => { setPath(location.pathname) });
  }, [ history ]);

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="logo-link">
            <img src={"/" + logo} alt="Logotype" />
          </Link>
          <Link to="/" className="link home">
            Touch
          </Link>
          {!isProject && !isCategory ?
            <span className="link">Setup</span>
            : null
          }
          {isProject ? 
            <Link to={`/`} className="link">
              Projects
            </Link>
            : null 
          }
          {isCategory ? 
            <Link to={`/projects/${ProjectStore.project.id}`} className="link">
              Categories
            </Link>
            : null 
          }
          {isBusiness ?
            <Link to={`/projects/${ProjectStore.project.id}/categories/${CategoryStore.category.id}`} className="link">
              Businesses
            </Link>
            : null
          }
        </div>        
      </div>
    </nav>
  );
}

export default Header;