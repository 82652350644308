import constants from "../constants/index";
const initState = {
  ads: null,
  ad: null
}

const AdsStore = (state = initState, action) => {
  switch (action.type) {
    case constants.setAds:
      return {
        ...state,
        ads: action.payload.ads    
      };
    case constants.setAd:
      return {
        ...state,
        ad: action.payload.ad    
      };
    case constants.addAd:
      return {
        ...state,
        ad: action.payload.ad  
      };
    case constants.editAd:
      return {
        ...state,
        ad: action.payload.ad  
      };
    case constants.deleteAd:
      return {
        ...state,
        ad: null  
      };
    default:
      return state;
  }
 }

export default AdsStore;