import constants from "../constants/index";
const initState = {
  projects: null,
  project: null
}

const ProjectStore = (state = initState, action) => {
  switch (action.type) {
    case constants.setProjects:
      return {
        ...state,
        projects: action.payload.projects    
      };
    case constants.setProject:
      return {
        ...state,
        project: action.payload.project    
      };
    case constants.addProject:
      return {
        ...state,
        project: action.payload.project  
      };
    case constants.editProject:
      return {
        ...state,
        project: action.payload.project  
      };
    case constants.deleteProject:
      return {
        ...state,
        project: null  
      };
    default:
      return state;
  }
 }

export default ProjectStore;