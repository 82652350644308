import constants from "../constants/index";
const initState = {
  businesses: null,
  business: null
}

const BusinessStore = (state = initState, action) => {
  switch (action.type) {
    case constants.setBusinesses:
      return {
        ...state,
        businesses: action.payload.businesses    
      };
    case constants.setBusiness:
      return {
        ...state,
        business: action.payload.business    
      };
    case constants.addBusiness:
      return {
        ...state,
        business: action.payload.business  
      };
    case constants.editBusiness:
      return {
        ...state,
        business: action.payload.business  
      };
    case constants.deleteBusiness:
      return {
        ...state,
        business: null  
      };
    default:
      return state;
  }
 }

export default BusinessStore;