import constants from "../constants/index";
const initState = {
  // Use default account Id only for development
  // accountId: "Local",
  accountId: null,
  assets: null
}

const EnplugStore = (state = initState, action) => {
  switch (action.type) {
    case constants.getUserId:
      return {
        ...state,
        accountId: action.payload.accountId    
      };
    case constants.getAssets:
      return {
        ...state,
        assets: action.payload.assets    
      };
    case constants.deleteAllAssets:
      return {
        ...state,
        assets: null  
      };
    case constants.public:
      return state;
    default:
      return state;
  }
 }

export default EnplugStore;