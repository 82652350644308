import { createAction } from 'redux-actions';
import { useDispatch } from 'react-redux';
import constants from "../constants/index";

const assetOptions = {
  showSchedule: true,
  initialTab: 'displays',
  successMessage: 'Saved config',
  loadingMessage: 'Saving...',
  showDeployDialog: true,
  showDuration: true
};

const actions = {
  getUserId: createAction(constants.getUserId),
  public: createAction(constants.public),
  getAssets: createAction(constants.getAssets),
  deleteAllAssets: createAction(constants.deleteAllAssets)
};

export default function useActions() {
  const dispatch = useDispatch();

  return {
    getUserId: defaultId => getUserId(dispatch, defaultId),
    addAsset: value => addAsset(dispatch, value),
    getAssets: () => getAssets(dispatch),
    editAsset: (asset, value) => editAsset(dispatch, asset, value),
    deleteAllAssets: assets => deleteAllAssets(dispatch, assets)
  }
};

function getUserId(dispatch, defaultId = null) {
  if (!defaultId) {
    window.enplug.account.getUser(response => {
      dispatch(actions.getUserId({ accountId: response.accountId }));
    }, onError);
  } else {
    dispatch(actions.getUserId({ accountId: defaultId }));
  }
};

function addAsset(dispatch, value) {
  window.enplug.account.getAssets(r => {
    let asset = {
      Id: null,
      Value: value,
      VenueIds: []
    };

    window.enplug.account.saveAsset(asset, assetOptions, r => {
      dispatch(actions.public());
      getAssets(dispatch);
    }, onError);
  }, onError);
  // dispatch(actions.public());
};

function getAssets(dispatch) {
  window.enplug.account.getAssets(response => {
    dispatch(actions.getAssets({ assets: response }));
  });
}

function editAsset(dispatch, asset, value) {
  asset.Value = value;
  window.enplug.account.saveAsset(asset, assetOptions, r => {
    dispatch(actions.public());
    getAssets(dispatch);
  }, onError);
}

function deleteAsset(id) {
  return new Promise((resolve, reject) => {
    window.enplug.account.deleteAsset(id + "", r => {
      window.enplug.dashboard.successIndicator("Delete success");
      resolve("");
    }, 
    error => {
      console.log(error);
      reject("Something goings wrong");
      window.enplug.dashboard.errorIndicator("Something goings wrong");
    });
  });    
}

function deleteAllAssets(dispatch, assets) {
  let list = [];
  assets.forEach(asset => {
    list.push(deleteAsset(asset.Id));
  });

  return Promise.all(list)
  .then(r => {
    dispatch(actions.deleteAllAssets({ assets: null }));
  });
}

function onError(error) {
  console.log(error);
  window.enplug.dashboard.errorIndicator("Something goings wrong");
}