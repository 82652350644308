import React, { useEffect } from 'react';
import { Route } from "react-router";
import LazyRoute from "lazy-route";
import routingData from "./data/Routing.json";
import Header from "./components/Header";
import EnplugActions from "./store/actions/EnplugActions";
import enplug from "../../vendor/js/enplug.sdk.min.js";
import "./assets/scss/main.scss";

const App = (props) => {
  const { getUserId } = EnplugActions();
  const routes = routingData.routing.map(renderRoute);

  // App did mount
  useEffect(() => { 
    if (window.location.href.indexOf(".local") == -1) { 
      getUserId();
    } else {
      getUserId("Local");
    }
  }, []);

  return (
    <div className="main">
      <Header />
      {routes}
    </div>
  );

  function renderRoute(route) {
    const component = import('' + route.componentPath),
      exact = route.exact ? route.exact : false;

    return (
      <Route exact={exact} 
        path={route.path}
        render={props => (
          <LazyRoute {...props}  
            component={component} 
          />
        )} 
      /> 
    );
  }
};

export default App;